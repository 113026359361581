import React from "react";
import QuestionWrapper, {QuestionWrapperProps} from "./QuestionWrapper";

type MultipleChoiceOption = {
    id: string;
    text: string;
    img?: string;
}

type MultipleChoiceQuestionProps = QuestionWrapperProps & {
    options: MultipleChoiceOption[];
    onClick: (id: string) => void;
    initialSelection: string | null;
}
const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({options, onClick, initialSelection, ...questionWrapperProps}) => {
    const {questionId, enabled} = questionWrapperProps;
    return (
        <QuestionWrapper {...questionWrapperProps}>
            <div className="btn-group flex-wrap">
                {options.map((option) => (
                    <MultipleChoiceQuestionOption
                        key={option.id}
                        option={option} enabled={enabled} questionId={questionId}
                        defaultChecked={option.id === initialSelection}
                        onClick={onClick}
                    />
                ))}
            </div>
        </QuestionWrapper>
    );
}

type MultipleChoiceQuestionOptionProps = {
    option: MultipleChoiceOption,
    enabled: boolean,
    questionId: string,
    defaultChecked?: boolean,
    onClick: (id: string) => void;
}
const MultipleChoiceQuestionOption: React.FC<MultipleChoiceQuestionOptionProps> = ({option, enabled, questionId, defaultChecked, onClick}) => {
    return (
        <React.Fragment>
            <input readOnly={!enabled} type="radio" className="btn-check" name={questionId} id={questionId + option.id} value={option.id} autoComplete="off" onClick={() => onClick(option.id)} defaultChecked={defaultChecked}/>
            <label className={"btn btn-outline-primary" + (enabled ? "" : " disabled")} htmlFor={questionId + option.id}>
                {option.img && <><img className="question-img" src={option.img} alt={option.text}/><br/></>}
                <span>{option.text}</span>
            </label>
        </React.Fragment>
    )
}

export default MultipleChoiceQuestion;
