import React from 'react';
import './App.css';
import UnreachableCaseError from "./UnreachableCaseError";
import MessageScreen from "./components/MessageScreen";
import {
    ALARM_TYPE,
    AlertData,
    FALSE_ALARM,
    FeedbackFormData,
    YES
} from "./types";
import {IS_SPECIFIC_ALARM, QUERY_PARAMS} from "./queryParams";
import FeedbackForm from "./components/FeedbackForm";
import {blankConfig, ConfigContext, configureAsync, SiteConfig} from "./config";
import Header from "./Header";
import {getAlertData, AlertContext} from "./api";

const SCREEN_FORM = 'SCREEN_FORM'
const SCREEN_THANKS = 'SCREEN_THANKS'
const SCREEN_ADJUST_THANKS = 'SCREEN_ADJUST_THANKS'
const SCREEN_TOKEN_EXPIRED_OR_INVALID = 'SCREEN_TOKEN_EXPIRED_OR_INVALID'
const SCREEN_LOADING = 'SCREEN_LOADING'
type AppScreen =
    | typeof SCREEN_FORM
    | typeof SCREEN_THANKS
    | typeof SCREEN_ADJUST_THANKS
    | typeof SCREEN_TOKEN_EXPIRED_OR_INVALID
    | typeof SCREEN_LOADING;


type AppProps = {
    error: boolean;
}
const App: React.FC<AppProps> = ({error}) => {
    const config = React.useContext(ConfigContext);
    const alert = React.useContext(AlertContext);

    const [screen, setScreen] = React.useState<AppScreen>(SCREEN_LOADING);
    const [submitting, setSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(false);

    React.useEffect(() => {
        if (screen === SCREEN_LOADING) {
            if (alert !== null) {
                const tokenExpired = alert.feedbackTokenExpiry - 300000 < Date.now();
                setScreen(tokenExpired ? SCREEN_TOKEN_EXPIRED_OR_INVALID : SCREEN_FORM);
            } else if (error) {
                setScreen(SCREEN_TOKEN_EXPIRED_OR_INVALID);
            }
        }
    }, [screen, alert, error]);

    const submitFunction = React.useCallback(async (data: FeedbackFormData) => {
        console.log('FeedbackFormData:', data);
        setSubmitting(true);
        setSubmitError(false);
        try {
            const response = await fetch(`${config.API_BASE_URL}/alertfeedback?feedback_token=${QUERY_PARAMS.feedbackToken}`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 401) {
                setScreen(SCREEN_TOKEN_EXPIRED_OR_INVALID);
            }
            if (response.status >= 300) {
                throw new Error(`Request failed with status ${response.status}`)
            }
        } catch (e) {
            setSubmitError(true);
            throw e;
        } finally {
            setSubmitting(false);
        }
    }, [config.API_BASE_URL]);

    switch (screen) {
        case SCREEN_LOADING:
            return <MessageScreen>
                Loading...
            </MessageScreen>
        case SCREEN_TOKEN_EXPIRED_OR_INVALID:
            return <MessageScreen>
                <p>Sorry, your alarm feedback link has expired or is invalid.<br/>
                To report alarm issues and get your Pippa's sensitivity changed, tap the alert in the <b>alerts</b> section of the Pippa app,
                then tap the <b>feedback</b> button.</p>
                {alert !== null ? (<p>
                    Alternatively you can find the <a href={`${config.ACCOUNT_BASE_URL}/alerts/${alert.alertId}/show`}>
                    alert under your account</a> and click <b>give feedback</b>.
                </p>) : (
                    <p>
                        Alternatively you can login to your account at <a href={config.ACCOUNT_BASE_URL}>{config.ACCOUNT_BASE_URL}</a> to find your alerts and give feedback.
                    </p>
                )}
            </MessageScreen>
        case SCREEN_THANKS:
            return <MessageScreen>Thanks for your feedback.<br/>You may now close the window.</MessageScreen>
        case SCREEN_ADJUST_THANKS:
            return <MessageScreen>Got it. We'll adjust your Pippa.<br/>This may take up to a week.<br/><br/>Thanks for your feedback.<br/>You may now close the window.</MessageScreen>
        case SCREEN_FORM:
            return (
                <FeedbackForm
                    key={SCREEN_FORM}
                    submitting={submitting}
                    submitError={submitError}
                    initialQuestion={IS_SPECIFIC_ALARM ? FALSE_ALARM : ALARM_TYPE}
                    clearSubmitErrors={() => setSubmitError(false)}
                    onSubmit={(data) => {
                        submitFunction(data).then((res) => {
                            if (data.adjustSensitivity === YES) {
                                setScreen(SCREEN_ADJUST_THANKS)
                            } else {
                                setScreen(SCREEN_THANKS);
                            }
                        }).catch(() => {});
                    }
                }/>
            );
        default:
            throw new UnreachableCaseError(screen);
    }
}

const AppWithConfig: React.FC = () => {
    const [config, setConfig] = React.useState<SiteConfig>(blankConfig)
    const [alert, setAlert] = React.useState<AlertData | null>(null)
    const [error, setError] = React.useState<boolean>(false)
    React.useEffect(() => {
        configureAsync().then((config => setConfig(config)));
        getAlertData().then((alert) => setAlert(alert)).catch((e) => setError(true));
    }, []);
    return (
        <ConfigContext.Provider value={config}>
            <AlertContext.Provider value={alert}>
                <div className="pt-2" style={{position: 'absolute', top: 72, bottom: 0, left: 0, right: 0, overflow: 'auto'}}>
                    <App error={error}/>
                    <div style={{height: '90vh'}} />
                </div>
                <Header/>
            </AlertContext.Provider>
        </ConfigContext.Provider>
    );
}

export default AppWithConfig;
