import {configureAsync} from './config'
import { QUERY_PARAMS } from './queryParams'
import { AlertData } from './types'
import React from "react";

export const AlertContext = React.createContext<AlertData | null>(null);

export async function getAlertData(): Promise<AlertData> {
    const config = await configureAsync();
    const response = await fetch(`${config.API_BASE_URL}/alertfeedback?feedback_token=${QUERY_PARAMS.feedbackToken}`)
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error("Failed to get alert data");
    }
}
