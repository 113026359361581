import React from "react";
import UnreachableCaseError from "../UnreachableCaseError";
import {FeedbackFormData, QuestionDefinition, QuestionType} from "../types";
import {getNextQuestion, getPrePopulatedValue, questionDefinitions} from "../questions";
import MultipleChoiceQuestion from "./questions/MultipleChoiceQuestion";
import TextAreaQuestion from "./questions/TextAreaQuestion";
import {AlertContext} from "../api";

type FeedbackFormProps = {
    onSubmit: (data: FeedbackFormData) => void;
    clearSubmitErrors: () => void;
    submitting: boolean;
    submitError: boolean;
    initialQuestion: QuestionType;
}
const FeedbackForm: React.FC<FeedbackFormProps> = ({onSubmit, clearSubmitErrors, submitting, submitError, initialQuestion}) => {
    const alert = React.useContext(AlertContext);
    const [displayedQuestionsWithTime, setDisplayedQuestionsWithTime] = React.useState<{t: number, questionType: QuestionType}[]>([{t: Date.now(), questionType: initialQuestion}]);
    const [showSubmit, setShowSubmit] = React.useState<boolean>(false);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);

    const appendQuestion = React.useCallback((questionDefinition: QuestionDefinition, answerId: string | null) => {
        while (true) {
            const formData = getFormData(formRef.current);
            const questionType = getNextQuestion(formData, questionDefinition, answerId);
            const prePopulatedValue = getPrePopulatedValue(questionType, alert);
            if (questionType !== null) {
                setDisplayedQuestionsWithTime((d) => [...d, {questionType, t: Date.now()}])
                if (prePopulatedValue !== null) {
                    questionDefinition = questionDefinitions[questionType]
                    answerId = prePopulatedValue
                } else {
                    break;
                }
            } else {
                setShowSubmit(true);
                break;
            }
        }
    }, [alert]);

    const goBack = React.useCallback(() => {
        setDisplayedQuestionsWithTime((d) => d.slice(0, d.length - 1));
        setShowSubmit(false);
        clearSubmitErrors();
    }, [clearSubmitErrors]);
    const lastQuestionType = displayedQuestionsWithTime[displayedQuestionsWithTime.length-1].questionType;

    const formRef = React.useRef<HTMLFormElement>(null);

    return (
        <form ref={formRef} className="container" onSubmit={(e) => {
                            e.preventDefault();
                            const data = getFormData(e.target as HTMLFormElement);
                            if (alert !== null) {
                                data.alarmType = alert.alertCategory;
                            }
                            onSubmit(data);
                        }}>
            {displayedQuestionsWithTime.map(({questionType, t}, i) => {
                const enabled = !submitting;
                const distanceFromEnd = displayedQuestionsWithTime.length - i - 1;
                const isCurrentQuestion = (distanceFromEnd === 0);

                const questionDefinition = questionDefinitions[questionType];
                switch (questionDefinition.inputType) {
                    case "select":
                        const onMultipleChoiceClick = (v: string) => {
                            if (distanceFromEnd >= 2) {
                                setShowAlert(true);
                            } else {
                                setShowAlert(false);
                            }
                            for (let c = 0; c < distanceFromEnd; c++) {
                                goBack();
                            }
                            appendQuestion(questionDefinition, v);
                        }

                        const prePopulatedValue = getPrePopulatedValue(questionType, alert);
                        return (
                            <MultipleChoiceQuestion
                                key={`${questionDefinition.type}-${t}`}
                                questionId={questionDefinition.type} question={questionDefinition.question}
                                options={questionDefinition.options}
                                enabled={enabled}
                                currentQuestion={isCurrentQuestion}
                                onClick={onMultipleChoiceClick}
                                initialSelection={prePopulatedValue}
                            />
                        )
                    case "multitext":
                        return (
                            <TextAreaQuestion
                                key={questionDefinition.type}
                                questionId={questionDefinition.type}
                                question={questionDefinition.question}
                                enabled={enabled}
                                currentQuestion={isCurrentQuestion}
                                rows={3}
                            />
                        )
                    case "text":
                        return (
                            <TextAreaQuestion
                                key={questionDefinition.type}
                                questionId={questionDefinition.type}
                                question={questionDefinition.question}
                                enabled={enabled}
                                currentQuestion={isCurrentQuestion}
                                rows={1}
                            />
                        )
                    default:
                        throw new UnreachableCaseError(questionDefinition);
                }
            })}
            {showAlert && (
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 col-xl-6">
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:" viewBox="-4 -4 24 24">
                                <path fill="currentColor" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            {'As you changed the answer to an earlier question, subsequent questions have been lost'}
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)} />
                        </div>
                    </div>
                </div>
            )}
            {(showSubmit || alwaysShowSubmitButton(getFormData(formRef.current), lastQuestionType)) && (
                <div className="d-grid gap-0 col-sm-6 mx-auto">
                    <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary mb-2"
                    >
                        {submitting && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
                        {submitting ? "Submitting..." : "Submit"}
                    </button>
                    {submitError && (
                        <div className="alert alert-danger" role="alert">
                            Error submitting feedback.
                        </div>
                    )}
                </div>
            )}
            {hasNextButton(getFormData(formRef.current), lastQuestionType) && (
                <div className="d-grid gap-1 col-sm-6 mx-auto">
                    <button
                        type="button"
                        disabled={submitting}
                        className="btn btn-primary mb-2"
                        onClick={() => appendQuestion(questionDefinitions[lastQuestionType], null)}
                    >
                        Next question
                    </button>
                </div>
            )}
        </form>
    );
}

export default FeedbackForm;

function hasNextButton(formData: FeedbackFormData, questionType: QuestionType): boolean {
    return (['multitext', 'text'].indexOf(questionDefinitions[questionType].inputType) >= 0) &&
        (getNextQuestion(formData, questionDefinitions[questionType], null) !== null);
}

function alwaysShowSubmitButton(formData: FeedbackFormData, questionType: QuestionType): boolean {
    const isLastQuestion = getNextQuestion(formData, questionDefinitions[questionType], null) === null;
    const showSubmitImmediately = ['multitext', 'text'].indexOf(questionDefinitions[questionType].inputType) >= 0;
    return isLastQuestion && showSubmitImmediately;
}

function getFormData(form: HTMLFormElement | null | undefined): FeedbackFormData {
    if (!form) {
        return {};
    }
    return Object.fromEntries(new FormData(form).entries());
}
