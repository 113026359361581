type QueryParams = {
    feedbackToken: string | null;
}

function getQueryParams(): QueryParams {
    const q = new URLSearchParams(window.location.search);
    return {
        feedbackToken: q.get('token'),
    }
}

export const QUERY_PARAMS = getQueryParams();
export const IS_SPECIFIC_ALARM = true;
