import React from "react";

const MessageScreen: React.FC = ({children}) => {
    return (
        <div className="container align-items-center justify-content-center">
            <div className="row justify-content-center pt-4">
                <div className="col-auto text-center">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default MessageScreen;
