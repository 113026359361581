export const ALARM_TYPE = 'alarmType';
export const FALSE_ALARM = 'falseAlarm';
export const IS_MULTIPLE = 'isMultiple';
export const STOVE_TYPE = 'stoveType';
export const OTHER_STOVE_TYPE = 'otherStoveType';
export const HAS_PAN = 'hasPan';
export const STOVE_ON = 'stoveOn'
export const USAGE_DESCRIPTION = 'usageDescription';
export const ADJUST_SENSITIVITY = 'adjustSensitivity'

export const BOILED_DRY = 'PAN_BOILED_DRY';
export const OVERHEAT = 'HOB_OVERHEATED';
export const LEFT_ON = 'HOB_LEFT_ON';
export const PRE_FIRE = 'PRE_FIRE';
export const FIRE = 'FIRE';

export const YES = 'YES';
export const NO = 'NO';
export const UNSURE = 'UNSURE';

export type YesNoUnsure =
    | typeof YES
    | typeof NO
    | typeof UNSURE;

export type AlarmType =
    | typeof BOILED_DRY
    | typeof OVERHEAT
    | typeof LEFT_ON
    | typeof PRE_FIRE
    | typeof FIRE;

export type QuestionType =
    | typeof ALARM_TYPE
    | typeof FALSE_ALARM
    | typeof IS_MULTIPLE
    | typeof STOVE_TYPE
    | typeof OTHER_STOVE_TYPE
    | typeof HAS_PAN
    | typeof STOVE_ON
    | typeof USAGE_DESCRIPTION
    | typeof ADJUST_SENSITIVITY;


export const STOVE_CERAMIC = 'CERAMIC';
export const STOVE_COIL = 'COIL';
export const STOVE_SOLID_PLATE = 'SOLID_PLATE';
export const STOVE_INDUCTION = 'INDUCTION';
export const STOVE_GAS = 'GAS';
export const STOVE_OTHER = 'OTHER';

export type StoveType =
    | typeof STOVE_CERAMIC
    | typeof STOVE_COIL
    | typeof STOVE_SOLID_PLATE
    | typeof STOVE_INDUCTION
    | typeof STOVE_GAS
    | typeof STOVE_OTHER

export type AlertData = {
    feedbackTokenExpiry: number;
    alertCategory: AlarmType;
    alertTimestamp: number;
    stoveType: StoveType | null;
    alertId: string;
}


export type FeedbackFormData = {
    [k in QuestionType]?: string;
}

export type QuestionDefinition = {
    type: QuestionType,
    defaultNextStep: QuestionType | ((f: FeedbackFormData) => (QuestionType | null)) | null,
    question: string,
} & ({
    inputType: 'select'
    options: {id: string, text: string, img?: string, nextStep?: QuestionType}[]
} | {
    inputType: 'multitext'
} | {
    inputType: 'text'
})