import {
    ADJUST_SENSITIVITY,
    ALARM_TYPE,
    AlertData,
    BOILED_DRY,
    FALSE_ALARM,
    FeedbackFormData,
    FIRE,
    HAS_PAN,
    IS_MULTIPLE,
    LEFT_ON,
    NO,
    OTHER_STOVE_TYPE,
    OVERHEAT,
    PRE_FIRE,
    QuestionDefinition,
    QuestionType,
    STOVE_CERAMIC,
    STOVE_COIL,
    STOVE_GAS,
    STOVE_INDUCTION,
    STOVE_ON,
    STOVE_OTHER,
    STOVE_SOLID_PLATE,
    STOVE_TYPE,
    UNSURE,
    USAGE_DESCRIPTION,
    YES
} from "./types";
import {IS_SPECIFIC_ALARM} from "./queryParams";

const alarmTypeDefinition: QuestionDefinition = {
    type: ALARM_TYPE,
    defaultNextStep: FALSE_ALARM,
    question: 'Which type of alarm was this?',
    inputType: 'select',
    options: [
        {id: OVERHEAT, text: 'Overheat'},
        {id: PRE_FIRE, text: 'Stove very hot'},
        {id: BOILED_DRY, text: 'Boiled dry'},
        {id: LEFT_ON, text: 'Left on'},
        {id: FIRE, text: 'Fire'},
    ]
}

const falseAlarmDefinition: QuestionDefinition = {
    type: FALSE_ALARM,
    defaultNextStep: IS_SPECIFIC_ALARM ? STOVE_TYPE : IS_MULTIPLE,
    question: 'Was this a false alarm?',
    inputType: 'select',
    options: [
        {id: YES, text: 'False alarm'},
        {id: NO, text: 'Real alarm', nextStep: USAGE_DESCRIPTION},
        {id: UNSURE, text: 'Unsure'},
    ]
}

const isMultipleDefinition: QuestionDefinition = {
    type: IS_MULTIPLE,
    defaultNextStep: STOVE_TYPE,
    question: 'Have you had this type of alarm more than once for the same reason?',
    inputType: 'select',
    options: [
        {id: YES, text: 'Yes'},
        {id: NO, text: 'No'},
        {id: UNSURE, text: 'Unsure'},
    ]
}

const stoveTypeDefinition: QuestionDefinition = {
    type: STOVE_TYPE,
    defaultNextStep: HAS_PAN,
    question: 'What type of stove do you have?',
    inputType: 'select',
    options: [
        {id: STOVE_CERAMIC, text: 'Ceramic', img: 'img/ceramic.jpg'},
        {id: STOVE_COIL, text: 'Coil', img: 'img/coil.jpg'},
        {id: STOVE_SOLID_PLATE, text: 'Solid plate', img: 'img/solidplate.jpg'},
        {id: STOVE_INDUCTION, text: 'Induction', img: 'img/induction.jpg'},
        {id: STOVE_GAS, text: 'Gas', img: 'img/gas.jpg'},
        {id: STOVE_OTHER, text: 'Other', img: 'img/other.jpg', nextStep: OTHER_STOVE_TYPE},
    ]
}

const otherStoveTypeDefinition: QuestionDefinition = {
    type: OTHER_STOVE_TYPE,
    defaultNextStep: HAS_PAN,
    question: 'Other stove type: please describe',
    inputType: 'text',
}

const hasPanDefinition: QuestionDefinition = {
    type: HAS_PAN,
    defaultNextStep: STOVE_ON,
    question: 'Was there a pan on the stove when this alarm happened?',
    inputType: 'select',
    options: [
        {id: YES, text: 'Yes'},
        {id: NO, text: 'No'},
        {id: UNSURE, text: 'Unsure'},
    ]
}

const stoveOnDefinition: QuestionDefinition = {
    type: STOVE_ON,
    defaultNextStep: USAGE_DESCRIPTION,
    question: 'Was the stove still on at the time of the alarm?',
    inputType: 'select',
    options: [
        {id: YES, text: 'Yes'},
        {id: NO, text: 'No'},
        {id: UNSURE, text: 'Unsure'},
    ]
}

const usageDescriptionDefinition: QuestionDefinition = {
    type: USAGE_DESCRIPTION,
    defaultNextStep: (formData) => autoAdjustSensitivityIsPossible(formData) ? ADJUST_SENSITIVITY : null,
    question: 'Please briefly describe how you were using your stove at the time of the alarm',
    inputType: 'multitext',
}

const adjustSensitivityDefinition: QuestionDefinition = {
    type: ADJUST_SENSITIVITY,
    defaultNextStep: null,
    question: 'Would you like us to adjust your Pippa to make it less sensitive?',
    inputType: 'select',
    options: [
        {id: YES, text: 'Make less sensitive'},
        {id: NO, text: 'Keep it the same'},
    ]
}

export const questionDefinitions: {[key: string]: QuestionDefinition} = {
    [alarmTypeDefinition.type]: alarmTypeDefinition,
    [falseAlarmDefinition.type]: falseAlarmDefinition,
    [isMultipleDefinition.type]: isMultipleDefinition,
    [stoveTypeDefinition.type]: stoveTypeDefinition,
    [otherStoveTypeDefinition.type]: otherStoveTypeDefinition,
    [hasPanDefinition.type]: hasPanDefinition,
    [stoveOnDefinition.type]: stoveOnDefinition,
    [usageDescriptionDefinition.type]: usageDescriptionDefinition,
    [adjustSensitivityDefinition.type]: adjustSensitivityDefinition,
}

export function getNextQuestion(formData: FeedbackFormData, currentQuestionDefinition: QuestionDefinition, answerId: string | null): QuestionType | null {
    let nextStep = currentQuestionDefinition.defaultNextStep;
    if (currentQuestionDefinition.inputType === 'select') {
        const answerOption = currentQuestionDefinition.options.filter((opt) => opt.id === answerId)[0];
        if (answerOption?.nextStep !== undefined) {
            nextStep = answerOption.nextStep;
        }
    }
    if (typeof nextStep === 'function') {
        nextStep = nextStep(formData);
    }
    if (nextStep === null) {
        return null;
    }
    return questionDefinitions[nextStep].type
}

export function getPrePopulatedValue(questionType: QuestionType | null, alert: AlertData | null): string | null {
    if (questionType === STOVE_TYPE && alert !== null) {
        return alert.stoveType
    } else {
        return null;
    }
}

function autoAdjustSensitivityIsPossible(data: FeedbackFormData): boolean {
    // Solid plate stove left on due to very slow cooling rate
    if (data.alarmType === LEFT_ON && data.stoveType === STOVE_SOLID_PLATE && data.stoveOn === NO) {
        return true;
    }
    // Overheat after turning stove off and removing pan since temperature can increase for a short time
    if (data.alarmType === OVERHEAT && data.hasPan === NO && data.stoveOn === NO) {
        return true;
    }
    return false;
}