import React from "react";

export type QuestionWrapperProps = {
    questionId: string;
    question: string;
    enabled: boolean;
    currentQuestion: boolean;
}

const QuestionWrapper: React.FC<QuestionWrapperProps> = (
    {
        questionId,
        question,
        children,
        enabled = false,
        currentQuestion = false,
    }
) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (ref.current && currentQuestion) {
                ref.current.scrollIntoView({behavior: 'smooth', block: 'center'})
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [currentQuestion]);

    return (
        <div ref={ref}>
            <div className="row" id={questionId}>
                <div className={'col text-center' + (enabled ? '' : ' text-muted') + (currentQuestion ? ' fw-bold ' : '')}>
                    {question}
                </div>
            </div>
            <div className="row justify-content-center mb-3">
                <div className="col d-flex justify-content-center">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default QuestionWrapper;
