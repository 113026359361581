import React from "react";
import QuestionWrapper, {QuestionWrapperProps} from "./QuestionWrapper";

type TextAreaQuestionProps = QuestionWrapperProps & {
    rows: number
}
const TextAreaQuestion: React.FC<TextAreaQuestionProps> = ({rows, ...questionWrapperProps}) => {
    const {questionId} = questionWrapperProps;
    return (
        <QuestionWrapper {...questionWrapperProps}>
            <textarea readOnly={!questionWrapperProps.enabled} className="form-control" name={questionId} id="comments" rows={rows} />
        </QuestionWrapper>
    )
}

export default TextAreaQuestion;