import React from "react";
import {AlarmType, BOILED_DRY, FIRE, LEFT_ON, OVERHEAT, PRE_FIRE} from "./types";
import UnreachableCaseError from "./UnreachableCaseError";
import {AlertContext} from "./api";

const Header: React.FC = () => {
    const alert = React.useContext(AlertContext);

    const alertDatetime = alert === null ? null : new Date(alert.alertTimestamp)

    return (
        <div className="position-absolute text-light text-center font-weight-bold" style={{
            top: 0, left: 0, right: 0, height: 72,
            backgroundColor: '#616161',
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
        }}>
            <strong>Pippa Alarm Feedback</strong>

            {alert !== null && (
                <>
                <div className="row">
                    <div className="col text-center">
                        {alarmTypeToString(alert.alertCategory)}
                    </div>
                </div>
                <div className="row">
                    <div className={"col text-center"}>
                        {alertDatetime !== null ? ` at ${alertDatetime.toLocaleTimeString()} on ${alertDatetime.toLocaleDateString()}` : ''}
                    </div>
                </div>
                </>
            )}

        </div>
    );
}


function alarmTypeToString(alarmType: AlarmType): string {
    switch (alarmType) {
        case BOILED_DRY:
            return 'Stove boiled dry';
        case OVERHEAT:
            return 'Stove overheating';
        case LEFT_ON:
            return 'Stove left on';
        case PRE_FIRE:
            return 'Stove very hot';
        case FIRE:
            return 'Fire';
        default:
            throw new UnreachableCaseError(alarmType);
    }
}

export default Header;
