import React from "react";

export interface SiteConfig {
    API_BASE_URL: string
    ACCOUNT_BASE_URL: string
}

export const configureAsync = function() {
    let configPromise: Promise<SiteConfig> | null = null;

    return async () => {
        if (configPromise === null) {
            configPromise = fetch('/config.json').then(res => res.json());
        }
        return await configPromise;
    }
}();

export const blankConfig: SiteConfig = {
    API_BASE_URL: '',
    ACCOUNT_BASE_URL: '',
}
export const ConfigContext = React.createContext<SiteConfig>(blankConfig);
